<template>
	<div>
		<ts-page-title :title="$t('cashManagementReport.pageTitle')" :breadcrumb="[
			{ text: $t('home'), href: '/' },
			{
				text: $t('cashManagementReport.pageTitle'),
				active: true
			}
		]" />
		<ts-panel>
			<ts-panel-wrapper>
				<div class="row">
					<div class="col-lg-3">
						<div class="card border-0 bg-gray-900">
							<div class="card-body">
								<div class="text-gray-500">
									<b>{{
										$t("cashManagementReport.filterCriteria")
									}}</b>
								</div>
							</div>
							<div class="widget-list rounded-bottom">
								<div class="mb-3 mt-3">
									<div class="col-md-12">
										<label class="form-col-label control-label">{{
											$t("cashManagementReport.branch")
										}}</label>
										<ts-branch-filter @filter="
											value =>
												(model.branch_id = value)
										" :isPlaceholder="true" />
									</div>
								</div>
								<div class="mb-3">
									<div class="col-md-12">
										<label class="form-col-label control-label required">{{
											$t( "cashManagementReport.effectiveDate")
										}}</label>
										<DatePicker v-model="model.effective_date" placement="bottom-end" style="width: 100%"
											:transfer="true" format="dd-MM-yyyy" @on-change="onChangeEffectiveDate"></DatePicker>
										<div class="text-danger" v-if="errors.has('effective_date')">
											{{ errors.first("effective_date") }}
										</div>
									</div>
								</div>
								<div class="mb-3">
                  <div class="col-md-12">
                    <label class="form-col-label control-label">
											{{ $t("staffLoan.excelTemplate") }}
										</label><br/>
                    <RadioGroup size="small" v-model="model.excel_type">
                      <Radio class="mt-1" :label="c.value" border v-for="(c, index) in excel" :key="index">
                          {{ c.label }}
                      </Radio>
                    </RadioGroup>
                  </div>
                </div>
								<div class="mb-3 tw-justify-end tw-flex tw-space-x-2">
									<ts-button outline color="success" @click.prevent="exportExcel" :waiting="exporting">
										<i class="far fa-file-excel" v-if="!exporting"></i>
										{{ $t("exportExcel") }}</ts-button>
									<ts-button color="danger" outline @click.prevent="preview" :waiting="waiting">
										<i class="far fa-file-pdf" v-if="!waiting"></i>
										{{ $t("previewPdf") }}</ts-button>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-9">
						<div id="container">
							<ts-preview-pdf v-model="waiting" :src="src" />
						</div>
					</div>
				</div>
			</ts-panel-wrapper>
		</ts-panel>
	</div>
</template>

<script>
import { Errors } from "form-backend-validation";
import moment from "moment";
import * as FileDownload from "downloadjs";
import { trim } from "lodash";

export default {
	name: "cashManagementReports",
	data() {
		return {
			waiting: false,
			exporting: false,
			errors: new Errors(),
			src: "",
			model: {
				excel_type: "standard",
				branch_id: [],
				effective_date: moment().format("DD-MM-YYYY")
			},
			excel: [
                {
                    value: "standard",
                    label: "Standard"
                },
                {
                    value: "raw_data",
                    label: "Raw Data"
                }
            ]
		};
	},
	methods: {
		preview() {
			this.errors = new Errors();
			this.waiting = true;
			this.src = "";
			this.$store
				.dispatch("report/creditOperation/cashManagementReport", this.model)
				.then(response => {
					this.src = response.url;
				})
				.catch(error => {
					this.notice({ type: "error", text: error.message });
					this.errors = new Errors(error.errors);
				})
				.finally(() => {
					this.waiting = false;
				});
		},
		exportExcel() {
			this.errors = new Errors();
			this.exporting = true;
			if (this.model.excel_type == "standard") {
			this.$store
				.dispatch(
					"report/creditOperation/cashManagementReportExportExcel",
					this.model
				)
				.then(response => {
					let filename = trim(
						response.headers['content-disposition']
							.substring(
								response.headers['content-disposition'].indexOf(
									'filename'
								)
							)
							.replace('filename=', ''),
						'"'
					)
					FileDownload(response.data, filename)
				})
				.catch(err => {
					let error = JSON.parse(
						String.fromCharCode.apply(null, new Uint8Array(err))
					)
					this.notice({ type: 'error', text: error.message })
					this.errors = new Errors(error.errors)
				})
				.finally(() => (this.exporting = false))
			}

			if (this.model.excel_type == "raw_data") {
				this.$store
					.dispatch(
						"report/creditOperation/cashManagementReportRawDataCSV",
						this.model
					)
					.then(response => {
						const anchor = document.createElement("a");
						anchor.href =
							"data:text/csv;charset=utf-8,%EF%BB%BF" +
							encodeURI(response.data);
						anchor.target = "_blank";
						anchor.download = "staff-loan-report.csv";
						anchor.click();
					})
					.catch(err => {
						let error = JSON.parse(
							String.fromCharCode.apply(null, new Uint8Array(err))
						);
						this.notice({ type: "error", text: error.message });
						this.errors = new Errors(error.errors);
					})
					.finally(() => (this.exporting = false));
			}
		},
		onChangeEffectiveDate(date) {
			this.model.effective_date = date;
		},
		notice(not) {
			this.$Notice[not.type]({
				title: "CASH MANAGEMENT REPORT",
				desc: not.text
			});
		}
	}
};
</script>
